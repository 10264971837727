<script>
import {ChevronRightIcon} from "vue-feather-icons"
import axios from 'axios'
import Footer from "@/components/footer"

let crypto = require('crypto');
let xregexp = require('xregexp');

export default {
  components: {
    ChevronRightIcon,
    Footer,
  },
  data() {
    return {
      restful: {
        scope: {
          id: '',
          scope: '',
          hashes: [],
        },
      },
      state: {
        username: null,
        isUsernameFound: null,
        isUsernameValid: null
      },
    }
  },
  methods: {
    hashUsername(string) {
      return crypto.createHash('sha1').update(string).digest('hex')
    },
    isUsernamePresent(hash) {
      return this.restful.scope.hashes.includes(hash)
    },
    validateUsername() {
      this.state.isUsernameValid = xregexp('[\\p{L}0-9\\-]{3,100}').test(this.state.username)
      this.state.isUsernameFound = null
    },
    checkUsername() {
      if (this.state.isUsernameValid !== true) {
        return
      }

      this.state.isUsernameFound = null
      let hash = this.hashUsername(this.state.username)

      axios.get(`https://api.haveibeenlinked.com/api/v1/scope/${hash.substring(0, 5)}`).then(
          response => {
            this.restful.scope = response.data.payload
            this.state.isUsernameFound = this.isUsernamePresent(hash)
          }
      ).catch(error => {
        if (error.response) {
          console.log(error.response.data)
          console.log(error.response.status);
        }
        alert('Shoot, an error occurred please try and refresh the page.')
      })
    },
  }
}
</script>

<template>
  <div>
    <div v-scroll-spy>
      <section class="hero-2 position-relative overflow-hidden"
               :style="{ 'background-image': 'url(' + require('@/assets/images/hero-2-bg.png') + ')'}" id="home">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-6">
              <h1 class="font-weight-semibold mb-4 hero-2-title">
                LinkedIn Data Breach Checker
              </h1>
              <p class="mb-3 text-muted">
                A large dataset containing 700 million LinkedIn accounts was made available for download this week after
                hackers tried to sell it earlier this year in June. The data is believed to be obtained by web scraping
                the LinkedIn website.
              </p>
              <h2 class="mb-3 font-size-18">
                Check if your username is present within the LinkedIn data breach.
              </h2>
              <p class="mb-3 text-muted">
                We have finalised ingesting the dataset, and we currently have processed <strong>350 873 947</strong>
                records. This tool does not store user-provided input and uses k-anonymity to ensure privacy.
              </p>

              <div class="custom-search">
                <div class="input-group mb-3">
                  <div class="input-group-prepend">
                    <span class="input-group-text" style="height: 52px !important;">linkedin.com/in/</span>
                  </div>
                  <input type="text" class="form-control" v-model="state.username" @input="validateUsername()">
                  <div class="input-group-append">
                    <button class="btn btn-primary" type="button" v-on:click="checkUsername">
                      <chevron-right-icon class=""></chevron-right-icon>
                    </button>
                  </div>
                </div>
                <p class="text-danger" v-if="this.state.isUsernameValid === false">
                  Please enter a valid username
                </p>
              </div>
              <p class="mb-3 text-danger" v-if="this.state.isUsernameFound === true">
                <strong>Oh, shoot!</strong> The username you entered was found in the data breach. Your name, phone
                number, physical address and gender could be included in this leak, and possibly also your education,
                email address, job history and birthday.
              </p>
              <p class="mb-3 text-success" v-if="this.state.isUsernameFound === false">
                Lovely! The username you entered was <strong>not</strong> found in the data breach.
              </p>
            </div>
            <div class="col-lg-6 col-sm-10 mx-auto ms-lg-auto me-lg-0">
              <div class="mt-lg-0 mt-4">
                <img src="@/assets/images/hero-2-img.png" alt="" class="img-xl-responsive"/>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer/>
    </div>
  </div>
</template>

<style>
.align-items-center {
  align-items: stretch !important
}
</style>