<script>
import {HeartIcon} from 'vue-feather-icons'

export default {
  data() {
    return {};
  },
  components: {
    HeartIcon
  }
};
</script>

<template>
  <footer class="footer" :style="{ 'background-image': 'url(' + require('@/assets/images/footer-bg.png') + ')'}">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="text-center m-4">
            <p class="text-white-50 f-15 mb-0">
              © {{ new Date().getFullYear() }} Have I Been Linked. Crafted with
              <heart-icon size="1.5x" class="text-danger" fill="#f43958"></heart-icon>
              by
              <a class="footer-link" href="https://jorritkleinbramel.nl/">Jorrit</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<style>
.footer-link {
  color: rgba(255, 255, 255, 0.5);
  transition: all 0.3s;
}

.footer-link:hover {
  color: #fff;
}

.footer {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

</style>